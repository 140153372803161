import data from "../../.sourceflow/blogs.json";
import CollectionHelper from "@/helpers/CollectionHelper";
import { sortByDate } from "@/functions/sortByDate";

class BlogCollectionHelper extends CollectionHelper {
  fetch({ limit = null, featured = null, parent = false, exclude = null, filter = null, author = null } = {}) {
    // let items = sortByDate(this.collection.getItems(), "publish_date");
    let items = this.collection.getItems();

    if (filter) {
      items = items.filter(filter);
    }

    if (featured) {
      items = items.filter((i) => i.featured === featured);
    }

    if (parent !== false) {
      items = items.filter((i) => i.parent.id === parent);
    }

    if (exclude) {
      items = items.filter((i) => !exclude.includes(i.id));
    }

    if (author) {
      items = items.filter((i) => i.author?.toLowerCase() === author?.toLowerCase());
    }

    if (limit) {
      items = items.slice(0, limit);
    }

    return items;
  }
}

export const blog_helper = new BlogCollectionHelper(data);
